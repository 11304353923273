.social-media-bar {
    /*background: green;*/
    width: 248px;
    display: inline-flex;
    justify-content: space-evenly;
}

.social-media-button {
    font-size: 35px;
    line-height: 62px;
    width: 50px;
    color: lightgray;
    transition: 0.1s;
    transition-timing-function: linear;
}

.social-media-button:hover {
    font-size: 42px;
    cursor: pointer;
    color: white;
}