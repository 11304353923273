
/*This is how you do absolute centering*/
.roundedBox
{
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    background: rgba(200,200,200, 0.5);
    height: 135px;
    border: 1px solid #000000;
    z-index: 8;
    scale: 1.4;
}

@media only screen and (min-device-width : 390px) and (max-device-width : 410px) {
    .roundedBox {
        scale: 1.28;
    }
}

@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px) {
    .roundedBox {
        scale: 1.35;
    }
}
.roundedBox label {
    margin-right: 8px;
    font-size: 20px;
    vertical-align: middle;
}

.emailInput {
    width: 169px;
}

@media screen and (max-width: 700px) {

    .emailInput {
        width: 269px;
    }
}

.invalid {
    outline: 1px solid red;
}

.separator {
    height: 10px;
}

.joinText {
    font-family: Verdana;
    text-align: center;
    margin-bottom: 7px;
    font-size: 14px;
}

.thanksText {
    width: 171px;
    text-align: center;
    margin-bottom: 7px;
    font-size: 14px;
    font-family: verdana;
}

.subtitle {
    font-size: 10px;
    font-family: verdana;
    font-weight: bold;
}

.enterSite {
    z-index: 8;
    margin-top: 240px;
    position: absolute;
    color: white !important;
    text-decoration: none;
    font-size: 24px;
    font-weight: normal;
}