header .logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 6px;
}

header .welcomePanel{
  float: right;
  font-family: verdana;
  font-size: 18px;
  margin-right: 10px;
  color: white;
}

header a {
  text-decoration: none;
  font-weight: normal;
  color: white !important;
}
.container {
  background: skyblue;
  flex: auto;
  margin-top: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container.stretch {
  justify-content: stretch;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
}

footer {
  background: black;
  color: white;
  margin-top: auto;
  text-align: center;
}

@media screen and (max-width: 700px) {

  footer {
    height: 128px
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


