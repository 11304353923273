.video-background
{
    position: absolute;
    width: 100%;
    height: 100%;
}

.overlay
{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.88;
    background: black;
}

@media (min-aspect-ratio: 16/9) {
    .video-background {
        /* height = 100 * (9 / 16) = 56.25 */
        height: 56.25vw;
    }
}

@media (max-aspect-ratio: 16/9) {
    .video-background {
        /* width = 100 / (9 / 16) = 177.777777 */
        width: 177.78vh;
    }
}