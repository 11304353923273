@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?mjpbbn');
  src:  url('fonts/icomoon.eot?mjpbbn#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?mjpbbn') format('truetype'),
    url('fonts/icomoon.woff?mjpbbn') format('woff'),
    url('fonts/icomoon.svg?mjpbbn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before {
  content: "\e90b";
}
.icon-peaked-cap-4 .path1:before {
  content: "\e902";
  color: rgb(53, 19, 190);
}
.icon-peaked-cap-4 .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(11, 14, 84);
}
.icon-peaked-cap-4 .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(41, 105, 255);
}
.icon-peaked-cap-4 .path4:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(0, 38, 255);
}
.icon-peaked-cap-4 .path5:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-peaked-cap-4 .path6:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(11, 14, 84);
}
.icon-peaked-cap-4 .path7:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(11, 14, 84);
}
.icon-peaked-cap-4 .path8:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(11, 14, 84);
}
.icon-peaked-cap-4 .path9:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(11, 14, 84);
}
.icon-applemusic:before {
  content: "\e900";
}
.icon-checkmark-outline:before {
  content: "\e901";
}
.icon-music:before {
  content: "\e911";
}
.icon-video-camera:before {
  content: "\e914";
}
.icon-tv:before {
  content: "\e95b";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-spotify:before {
  content: "\ea94";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-soundcloud:before {
  content: "\eac3";
}
