header .navPanel {
    display: inline-block;
    float: right;
}

header .navPanel .navItem {
    display: inline-block;
    font-size: 24px;
    margin-right: 10px;
    vertical-align: middle;
    cursor: pointer;

}

header .navPanel .navItem a {
    color: lightgrey !important;
    text-decoration: none;
}

header .navPanel .navItem.active a {
    color: white !important;
}

header .navPanel .navItem a:hover {
    color: white !important;
}