* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  scroll-behavior: smooth !important;
}

html {
  scroll-behavior: smooth !important;;
}

body {
  margin: 0;
  font-family: impact, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

@font-face {
  font-family: 'Roboto'; /*a name to be used later*/
  src: local('Roboto'), url('../public/Roboto-Regular.ttf'); /*URL to font*/
}
@font-face {
  font-family: 'Impact'; /*a name to be used later*/
  src: local('Impact'), url('../public/Impact.ttf'); /*URL to font*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: impact, sans-serif;
  font-size: 22px;
  border: 1px solid #B6B6B6;
  border-radius: 5%;
  background: white;
}

button:active
{
  border: 1px solid red;
}
button:hover {
  border: 1px solid #575757;
  background: rgb(232, 232, 232);
}
button:focus {
  border: 1px solid black;
}

input[type="text"] {
  border-radius: 3%;
  height: 20px;
  border: 1px solid darkgrey;
}

input[type="text"]:focus {
  border: 1px solid black;
  outline: none;
}

header {
  background: darkslategrey;
  color: white !important;
  padding-left: 6px;
  font-size: 32px;
  line-height: 44px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

header .logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 6px;
}

article {
  font-family: Arial, Helvetica, sans-serif;
}

.carousel .thumbs-wrapper {
  margin:  0;
  padding: 0;
  overflow: hidden;
  display: flex;

  justify-content: center;
}

:-webkit-any(article, aside, nav, section) h1 {
  font-size: 5.3em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  z-index: 1;
}

.smaller {
  font-size: 20px;
}

.no-top-margin {
  margin-top: 0;
}

.centered-text {
  text-align: center;
}

a {
  font-weight: bold;
  color: deepskyblue;
}

a.local-link, a.local-link:visited {
  color: white;
  text-decoration: none;
}

a:visited {
  color: deepskyblue;
}

h2.smaller-margin {
  margin-bottom: 10px;
}

.btn-link {
  border-radius: 0.25rem;
  background-color: rgb(67 144 132);
  padding: 0.5rem 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: white !important;
  text-decoration: none;
  transition: 500ms;
  font-family: roboto;
}

.btn-link:hover {
  background-color: rgb(27 104 92);
}

.carousel .thumbs-wrapper {
  margin: 0;
}

.carousel .thumbs {
    padding: 0;
}
@media screen and (max-width: 700px) {

  .btn-link {
    font-size: 0.53em;
  }

}