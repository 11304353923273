.companies-panel {
    margin-top: 42px;
    display: flex;
    justify-content: space-evenly;
}

.companies-panel img {
    height: 62px;
    border: 1px solid grey;
}

@media screen and (max-width: 700px) {

    .companies-panel {
        margin-top: 42px;
        display: flex;
        flex-wrap: wrap;
    }
}