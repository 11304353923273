.MusicItemsContainer {
    display: flex;
}

.musicItem {
    width: 222px;
    height: 222px;
    border-radius: 3px;
    background-color: #cccccc;
    background-size: contain;
    background-repeat: no-repeat;
}

.musicItem .musicItemSpace {
    height: 75%;
}

.musicItem .platformsNavBar
{   background-color: rgba(32,32,32, 0.8);
    height: 25%;
    justify-content: space-evenly;
    align-items: center;
    transition: 350ms ;
    display: flex;
    opacity: 0;
}

.musicItem .camera {
    padding: 8px;
    color: cadetblue;
    background: black;
    height: 22px;
    text-align: center;
    width: 22px;
    border-radius: 12px;
    margin: 3px;
}

.musicItem:hover .platformsNavBar
{
    opacity: 1;
}

.musicItem .platformsNavBar .platformItem {
    height: 38px;
    cursor: pointer;
}

.musicItem .platformsNavBar .platformItem img{
    height: 38px;
}

.musicItem .header {

}