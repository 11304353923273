.articleContainer {
    display: flex;
    flex: auto;
    background: green;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: "Roboto", sans-serif;
}

.pageHeader {
    background-color: lightgrey;
    background-attachment: fixed;
    background-repeat:no-repeat;
    background-size:cover;
    background-position-x:center;
    height: 305px;
    min-height: 305px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.pageHeader .overlay
{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.53;
    background: black;
    z-index: 0;
}

.pageBody {
    font-size: 1.4em;
    background: rgb(26, 26, 26);
    color: white;
    flex: auto;
    display: flex;
    justify-content: center;
    z-index: 1;
}

.pageBody .centeredBlock {
    /* background: salmon; */
    width: 50%;
}

.screenshot {
    width: 100%;
    max-width: 100%;
}

.animatedSprite {
    font-size: 6em;
    text-align: center;
    animation-name: rotateAnimations;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    transform: rotateY(0deg);
}

.animatedSpriteSmaller {
    font-size: 3.5em;
    text-align: center;
    animation-name: rotateAnimations;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    transform: rotateY(0deg);
}

.itemsContainer {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 32px;
}
@keyframes rotateAnimations {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

@media screen and (max-width: 700px) {

    .pageHeader {
        height: 180px;
        min-height: 180px;
    }

    .pageHeader h1 {
        font-size: 4.4em;
    }
    .pageBody .centeredBlock {
        /* background: salmon; */
        width: 88%;
    }

}